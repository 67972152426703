@media only screen and (max-width: 991px) {
    .pne-controll-response__button {
        margin-top: 10px;
        width: 100%;
    }
}

.item-card {
    padding: 10px;
    border: 1px solid black;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 3px 3px 6px 3px #e7e5e5;
    &__info {
        b {
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .ant-collapse-item {
        border-bottom: none !important;
    }
    .ant-collapse-content-box {
        color: rgba(0, 0, 0, 0.85) !important;
        overflow-x: hidden;
    }
}

.list-item {
    width: 100%;

    .filter-collapse {
        margin-bottom: 10px;
    }
    .item-card {
        padding: 10px;
        border: 1px solid black;
        border-radius: 10px;
        margin-bottom: 10px;
        box-shadow: 3px 3px 6px 3px #e7e5e5;
        &__info {
            b {
                color: rgba(0, 0, 0, 0.85);
            }
        }
        .ant-collapse-item {
            border-bottom: none !important;
        }
        .ant-collapse-content-box {
            color: rgba(0, 0, 0, 0.85) !important;
            overflow-x: hidden;
        }
    }
    .pagination {
        text-align: center;
        margin: 10px 0;
        li {
            margin-bottom: 10px;
        }
    }
    .filter-select {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}
