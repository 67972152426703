.new-form {
    padding-bottom: 50px;
    .new-form-content {
        opacity: 0;
    }
    .ant-form-item {
        margin-bottom: 0;
    }
    .ant-select .ant-select-selector {
        height: 34px;
        .ant-select-selection-placeholder,
        .ant-select-selection-item {
            line-height: 34px;
        }
    }
    .ant-select-single:not(.ant-select-customize-input) {
        .ant-select-selector {
            .ant-select-selection-search-input {
                height: 28px;
            }
        }
    }
    .ant-select-selection-overflow {
        top: -6px;
    }
    .item-row {
        margin-bottom: 15px;
        min-height: 34px;
    }
    .item-label {
        line-height: 34px;
        display: block;
    }
    .item-space {
        width: 20px;
        text-align: center;
        line-height: 34px;
    }
    .float-label {
        position: relative;
        margin-bottom: 15px;
        .ant-select {
            .ant-select-selector {
                padding: 8px 12px 4px 11px;
                height: 34px;
                .ant-select-selection-placeholder,
                .ant-select-selection-item {
                    line-height: 20px;
                }
                .ant-select-selection-search {
                    top: 3px;
                }
            }
        }
        .ant-input {
            padding: 8px 12px 4px 11px;
            border-radius: 2px;
        }
        input.ant-input {
            height: 34px;
        }
        .label {
            font-size: 14px;
            font-weight: normal;
            position: absolute;
            pointer-events: none;
            left: 12px;
            top: 7px;
            transition: 0.2s ease all;
            color: #969696;
        }

        .label-float {
            top: -6px;
            left: 6px;
            font-size: 12px;
            background: #ffffff;
            padding: 0 5px;
            z-index: 2;
            border-radius: 6px;
            line-height: 14px;
        }
        .ant-input-group .ant-input:hover {
            z-index: inherit;
        }
        .ant-input-group-addon {
            padding: 8px 11px 1px 11px;
            height: 34px;
            line-height: 22px;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
            box-shadow: 0 0 0 30px white inset !important;
        }
        .ant-picker {
            height: 34px;
            padding: 8px 12px 4px 11px;
        }
    }
}
