.pn-container-student {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.name-student {
    margin-left: 10px;
    font-weight: 700;
    font-size: 16px;
}

.student-form {
    .position {
        .ant-select-selector {
            height: auto !important;
            .ant-select-selection-overflow {
                top: 0px;
            }
        }
    }
}
